import * as React from "react"
import { graphql } from 'gatsby';

import NotFound from 'pages/404';

import DataProvider from 'providers/data';

import Layout from 'templates/Layout';
import Content from 'templates/Content';

import Objectives from "components/our-approach/Objectives";
import DiscoverInvestPerform from "components/our-approach/Dip";
import KeyDiffs from "components/our-approach/KeyDifferentiators";

const OurApproach = (props) => {
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!page !== false) && props.providerStatusText === 'LOADED') {
		page = props.providerResponse.data[0];
	}
	if (!!page === false && props.providerStatus >= 2) return <NotFound />; // We only go to a page not found after the API loads or has an error and there's no page found.
	if (props.providerStatus < 2) { // This prevents any flash of content until the API is loaded -- but if the API takes a while to respond the effect would be the page loads slower. You could also use state to reveal content with a transition
		return null;
	}

	const ourApproachCards = [];
	ourApproachCards.push({
		title: page.ourApproach1Title,
		icon: page.ourApproach1Icon,
		content: page.ourApproach1Content,
		step: page.ourApproach1Step,
		titleAlign: page.ourApproach1TitleAlign,
	});
	ourApproachCards.push({
		title: page.ourApproach2Title,
		icon: page.ourApproach2Icon,
		content: page.ourApproach2Content,
		step: page.ourApproach2Step,
		titleAlign: page.ourApproach2TitleAlign,
	});
	ourApproachCards.push({
		title: page.ourApproach3Title,
		icon: page.ourApproach3Icon,
		content: page.ourApproach3Content,
		step: page.ourApproach3Step,
		titleAlign: page.ourApproach3TitleAlign,
	});

	return (
		<div className={`loadWrap ${props.providerStatus >= 2 ? 'loaded' : ''}`}>
			{ props.providerStatus >= 2 &&
				<Layout showContactForm={page?.showContactForm} showNewsletterSignUp={page?.showNewsletterSignUp} hoverboards={page?.hoverboards}>
					<section className="largeContentBlock">
						<Content hideContent={true} {...page} />
						<Objectives title={page.ourApproachObjectivesTitle} objectives={page.ourApproachObjectives} footer={page.ourApproachObjectivesFooter} />
						<DiscoverInvestPerform title={page.ourApproachTitle} cards={ourApproachCards} />
						<KeyDiffs data={page.keyDifferentiators} />
					</section>
				</Layout>
			}
		</div>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ContentPage" apiParams={{ uri: props.location.pathname }}><OurApproach {...props} /></DataProvider>
export default getData;

export const query = graphql`
	query {
	  allContentPage(
	    filter: {uri: {eq: "/our-approach/"}, approved: {eq: true}, archived: {eq: false}, deleted: {eq: false}}
	  ) {
		edges {
			node {
			  pageTitle
			  meta
			  content {
				main
			  }
			  showContactForm
			  showNewsletterSignUp
			  headlineBody
			  headlineTitle
			  headlineImage
			  headlineType
			  hoverboards

			  solutions
			}
		  }
		}
	}
`