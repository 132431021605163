import * as React from "react"
import zparse from 'helpers/zparse';

const Objectives = ({title, objectives, footer}) => {
    const Objective = (obj) => {
        return (
            <div className="cell objective">
                <div className="icon-wrapper">
                    <img src={obj.icon} alt="" />
                </div>
                <div className="content-wrapper">
                    {obj.children}
                </div>
            </div>
        )
    }
    
    return (
        <section id="objectives">
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12 medium-9">
                        <h2>{title}</h2>
                    </div>
                </div>
                <div className="grid-x grid-padding-x small-up-1 medium-up-2">
                    {!!objectives !== false && objectives.map(datum => {
                        return (
                            <Objective key={datum.title} icon={datum.icon}>
                                <h3>{datum.title}</h3>
                                <div class="lg-p">{datum.description}</div>
                            </Objective>
                        )
                    })}
                </div>
                <div className="grid-x grid-padding-x">
                    <div className="cell small-12">
                        {zparse(footer)}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Objectives;