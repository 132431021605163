import * as React from "react"
// import { graphql, StaticQuery } from "gatsby";
import { default as Card } from 'components/ColorCard';

const DiscoverInvestPerform = ({ title, cards }) => {

	return (
		<section id="dip">
			<div className="grid-container">
				<div className="grid-x grid-padding-x">
					<div className="cell small-12 medium-9 title">
						<h2>{title}</h2>
					</div>
				</div>
				<div className="grid-x grid-padding-x">
					<div className="cell flx">
						{!!cards.length && cards.map((datum, index) => {
							const { title, icon, content, step, titleAlign } = datum;
							if (!title) return null;
							return (
								<div key={`datum-${index}`} className={`color-card-holder`}>
									<Card {...{ title, content, icon, titleAlign }} />
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</section>
	)
}

export default DiscoverInvestPerform;