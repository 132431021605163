import * as React from "react"
import zparse from 'helpers/zparse';

const KeyDiffs = ({data}) => {
    
    return (
        <>
        {!!data !== false && data.map(({title, description, image}, index) => (
            <section key={title} className="keydiff" id={(index === 0 ? 'keydiff' : '')}>
                <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                        <div className={`cell small-12 medium-6 ${index+1 % 2 === 0 ? 'medium-order-2 medium-offset-1': ''}`}>
                            <h2>{title}</h2>
                            {zparse(description)}
                        </div>
                        <div className={`cell small-12 medium-5 ${index+1 % 2 === 0 ? 'medium-order-1': 'medium-offset-1'}`}>
                            { image && <img src={image} alt="" /> }
                        </div>
                    </div>
                </div>
            </section>
        ))}
        </>
    )
}

export default KeyDiffs;